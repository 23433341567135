<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable v-model:expandedRows="expandedRows" :value="products" dataKey="especialidad_id"
					@rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
					<template #header>
						<div class="table-header">
                            <Dropdown v-model="especialidad" :options="especialidades" optionLabel="nombre" placeholder="Selecciona..." @change="Filtrar"></Dropdown>

							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column expander style="width: 5rem" />
					<Column field="especialidad" header="Especialidad">
						<template #body="slotProps">
                            <span v-if="slotProps.data.act_espec">{{slotProps.data.especialidad}} (Activo)</span>
							<span v-else>{{slotProps.data.especialidad}} (No Activo)</span>
						</template>
					</Column>
					<template #expansion="slotProps">
						<div class="p-3">
							<DataTable :value="slotProps.data.arancel">
								<Column field="nombre" header="Examen/Estudio/Procedimiento">
									<template #body="slotProps">
										{{slotProps.data.nombre}}
									</template>
								</Column>
								<Column header="Precio" headerStyle="width: 150px">
									<template #body="slotProps">
										{{slotProps.data.moneda}} {{Moneda(slotProps.data.monto)}}
									</template>
								</Column>
								<Column header="Válido por" headerStyle="width: 150px">
									<template #body="slotProps">
										{{slotProps.data.validopor}}
									</template>
								</Column>
								<Column header="% Especialista" headerStyle="width: 150px">
									<template #body="slotProps">
										{{slotProps.data.porcentaje}}% ({{slotProps.data.moneda}}{{Moneda(slotProps.data.monto*slotProps.data.porcentaje/100)}})
									</template>
								</Column>
								<Column header="Estatus" headerStyle="width: 150px">
									<template #body="slotProps">
										<span class="p-tag p-tag-success"  v-if="slotProps.data.activo">Activo</span>
										<span class="p-tag p-tag-danger"  v-else>No Activo</span>
										<span class="p-tag p-tag-info"  v-if="slotProps.data.mensual">Mensual</span>
									</template>
								</Column>
								<Column header="Acciones" headerStyle="width: 150px">
									<template #body="slotProps">
										<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="Editar(slotProps.data)" v-if="edit"/>
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="Confirmar(slotProps.data)" v-if="del"/>
									</template>
								</Column>
							</DataTable>
						</div>
					</template>
				</DataTable>
				<!--
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				</DataTable>
				-->
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">       
				<div class="grid fluid mt-3">
					<div class="col-4">
						<label for="name">Especialidad</label>
						<Dropdown v-model="product.especialidad" :options="this.$store.state.especialidades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
						<small class="p-invalid" v-if="!product.especialidad">Es requirido</small>
					</div>
					<div class="col-8">
						<label for="name">Nombre</label>
						<InputText id="name" v-model.trim="product.nombre" required="true"/>
						<small class="p-invalid" v-if="!product.nombre">Name is required.</small>
					</div>
					<div class="col-3">
						<label for="name">Moneda</label>
						<Dropdown v-model="product.moneda" :options="this.$store.state.monedas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
					</div>
					<div class="col-3">
						<label for="price">Precio</label>
						<InputNumber v-model="product.monto" mode="currency" :currency="product.moneda.code" locale="en-US" />
                        <small class="p-invalid" v-if="!product.monto">Es requerido.</small>
					</div>
					<div class="col-2">
						<label for="name">Válido por</label>
						<InputNumber id="cedula" v-model="product.validopor" integeronly required="true" min="1"/>
						<small class="p-invalid" v-if="!product.validopor">Es requerido.</small>
					</div>
					<div class="col-2">
						<label for="name">% Especialista</label>
						<InputNumber v-model="product.porcentaje" suffix="%" :minFractionDigits="2" :min="0" :max="100" />
						<small class="p-invalid" v-if="!product.porcentaje">Es requerido.</small>
					</div>
					<div class="col-2">
						<h6>Pago Mensual</h6>
						<InputSwitch v-model="product.mensual"  style="margin-top: 0"/>
					</div>
					<div class="col-2">
						<h6>Estatus</h6>
						<InputSwitch v-model="product.activo"  style="margin-top: 0"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" :header="'Eliminar ID'+product.id" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Aranceles');
	import Funciones from "../service/Class";
	const Aplic = new Funciones();

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				especialidad: {nombre: 'TODOS', code: 0},
				especialidades: [],
                dataprincipal: [],
				expandedRows: [],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.especialidades = [{nombre: 'TODOS', code: 0}].concat(this.$store.state.especialidades);
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result;
                    this.dataprincipal = response.result;
					this.$store.commit('Loading');
				}); 
			},
            Filtrar(){
                if(this.especialidad.code>0){
                    this.products = this.dataprincipal.filter(val => val.especialidad_id == this.especialidad.code);
                } else {this.products = this.dataprincipal;}
            },
			Nuevo() {
				this.nuevo = true;
				this.product = {};
                this.product.id = '';
                this.product.moneda =  this.$store.state.monedas[0];
                this.product.monto = 0;
                this.product.validopor = 1;
				this.productDialog = true;
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
                this.product.especialidad =  this.$store.state.especialidades.filter(val => val.code == this.product.especialidad_id)[0];
                this.product.moneda =  this.$store.state.monedas.filter(val => val.id == this.product.moneda_id)[0];

                this.productDialog = true;
			},
			Guardar() {
				if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.especialidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Especialidad', life: 5000});
				} else if (this.product.moneda == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Moneda', life: 5000});
				} else if (this.product.monto <= 0) {
					this.$toast.add({severity:'=error', summary: 'Mensaje', detail: 'Introduzca Precio', life: 5000});
				} else if (this.product.validopor == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Válido por', life: 5000});
				} else if (!(this.product.porcentaje>=0)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca % para el Especialista', life: 5000});
				} else {
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
						product: this.product,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Mostrar();
							this.productDialog = false;
							this.product = {};
						}
					});
				}
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
			Moneda(value) {
				return Aplic.Moneda(value);
			},
			expandAll() {
                this.expandedRows = this.products.filter(p => p.id);
            },
            collapseAll() {
                this.expandedRows = null;
            },

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
